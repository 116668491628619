import './init';

import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';
import { defineOverrideEnv } from '@purple/react-components';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';
import AuthNew from './authNew';
import registerServiceWorker from './registerServiceWorker';

import './i18n'; // needed!

if (import.meta.env.VITE_ENV_DETECTION_OVERRIDE) {
  defineOverrideEnv(import.meta.env.VITE_ENV_DETECTION_OVERRIDE as any);
}

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    dsn: 'https://ffbfda3d5a604fd1af2204048f4a41ba@sentry.sprylabprojects.com/10',
    integrations: [new BrowserTracing()],

    // TODO(kk): see how we can pass the deployment environment
    // environment: "production",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

async function startApp() {
  await tryLoadAndStartRecorder({
    // See https://app.meticulous.ai/projects/Sprylab/ACM
    projectId: 'GjdsuzSkyBUOW5yz510mMnsWm23UrefN2olwDtwM',
  });

  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  if (window.location.href.endsWith('/authnew')) {
    root.render(<AuthNew />);
  } else {
    root.render(<App />);
  }
  registerServiceWorker();
}

// noinspection JSIgnoredPromiseFromCall
startApp();
